
* {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

html, body {
  min-height: 100%;
  height: auto;
}

body {
  margin: 0;
  background-color: white;
  color: #111827;
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}
